import { IContentSection } from "~/components/organisms/ContentsNavigator/types";
import projectSectionConfig from "~/utilities/config/projectSectionConfig";
import { Section } from "~/utilities/enums/ProjectSections";

// scrollOffset  = height of ContentsNavigator + paddingY of section * 2 (because paddingBottom of above section + paddingTop currentSection) + x (more space u want)
export const SCROLL_OFFSET = -130; // 73 + 24 * 2 + ...
export const SCROLL_OFFSET_MB = -95; // 53 + 18 *2 + ...

export const projectSections: IContentSection[] = [
  {
    targetId: Section.DESCRIPTION,
    label: projectSectionConfig[Section.DESCRIPTION].title,
  },
  {
    targetId: Section.PROJECT_DETAILS,
    label: projectSectionConfig[Section.PROJECT_DETAILS].title,
  },
  {
    targetId: Section.VIRTUAL_TOUR,
    label: projectSectionConfig[Section.VIRTUAL_TOUR].title,
  },
  {
    targetId: Section.SITE_PLAN,
    label: projectSectionConfig[Section.SITE_PLAN].title,
  },
  {
    targetId: Section.LOCATION_MAP,
    label: projectSectionConfig[Section.LOCATION_MAP].title,
  },
  {
    targetId: Section.GALLERY,
    label: projectSectionConfig[Section.GALLERY].title,
  },
  {
    targetId: Section.UNIT_MIX,
    label: projectSectionConfig[Section.UNIT_MIX].title,
  },
  {
    targetId: Section.AVAILABLE_UNITS,
    label: projectSectionConfig[Section.AVAILABLE_UNITS].title,
  },
  {
    targetId: Section.FLOOR_PLANS,
    label: projectSectionConfig[Section.FLOOR_PLANS].title,
  },
  {
    targetId: Section.SALES_TRANSACTION,
    label: projectSectionConfig[Section.SALES_TRANSACTION].title,
  },
];
